<template>
  <div>
    <b-modal id="consent-modal-id" ref="ref-consent-modal" centered title="Consent Letter" ok-title="Accept"
      @ok.prevent="acceptConsent">
      <br />
      <div class="consent-html-text">
        <div v-html="consent_html_text" />
      </div>
      <br />
      <b-form-checkbox id="accept-checkbox-id" v-model="form.student_consent" name="accept-checkbox-name" value="Y"
        unchecked-value="N">
        I accept the terms and use
      </b-form-checkbox>
      <br />
    </b-modal>

    <b-modal id="go-to-modal" ref="ref-go-to-modal" centered ok-title="Accept" @ok.prevent="acceptConsent" hide-footer
      no-close-on-esc no-close-on-backdrop hide-header-close hide-header>
      <br />
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary mr-1" @click="() => goToUniversitySearch()">
          Find Courses
        </button>

        <button class="btn btn-primary" @click="() => {
          $refs['ref-go-to-modal'].hide();
        }
          ">
          Add Documents
        </button>
      </div>

      <br />
    </b-modal>

    <form-wizard ref="wizard" color="#5367FF" :title="null" :subtitle="null" shape="round" finish-button-text="Save"
      back-button-text="Previous" next-button-text="Save & Next" class="mb-3 profile-form-wizard"
      @on-complete="onComplete">
      <!-- First Tab -->
      <tab-content title="Personal Details" :before-change="validatePersonalDetails">
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-avatar ref="previewEl" :src="FILESURL + form.user_profile_image" size="90px" rounded />
            </b-link>
            <!--/ avatar -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
              @click="$refs.refStudentProfileInputEl.$el.click()">
              Upload
            </b-button>
            <b-form-file ref="refStudentProfileInputEl" accept=".jpg, .png, .gif" :hidden="true" plain
              v-model="uploaded_image" @input="uploadProfileImage" />
            <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
          </b-media-body>
        </b-media>
        <br />
        <validation-observer ref="personalDetails" tag="form">
          <div class="form-card">
            <b-row>
              <b-col md="12" style="background-color: var(--primary)">
                <h4 class="form-group">PERSONAL DETAILS</h4>
                <br />
              </b-col>
              <b-col md="4">
                <b-form-group label="First Name" label-for="mc-first-name">
                  <validation-provider #default="{ errors }" name="First Name" rules="required">
                    <b-form-input maxlength="255" id="mc-first-name" placeholder="First Name"
                      v-model="form.user_firstname" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Surname" label-for="mc-last-name">
                  <validation-provider #default="{ errors }" name="Last Name" rules="required">
                    <b-form-input maxlength="255" id="mc-last-name" placeholder="Last Name"
                      v-model="form.user_lastname" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Date of birth" label-for="mc-dob">
                  <validation-provider #default="{ errors }" name="Date Of Birth" rules="required">
                    <!-- {{ form.student_DOB }} -->
                    <!-- <b-form-input
                      id="mc-dob"
                      type="date"
                      v-model="form.student_DOB"
                    /> -->
                    <datepicker style="backgroud-color: white" id="mc-dob" v-model="form.student_DOB"
                      bootstrap-styling />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Gender">
                  <b-row>
                    <b-col>
                      <b-form-radio v-model="form.student_gender" plain value="female">
                        Female
                      </b-form-radio>
                    </b-col>
                    <b-col>
                      <b-form-radio v-model="form.student_gender" plain value="male">
                        Male
                      </b-form-radio>
                    </b-col>
                    <b-col>
                      <b-form-radio v-model="form.student_gender" plain value="other">
                        Other
                      </b-form-radio>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Nationality" label-for="mc-nationality">
                  <validation-provider #default="{ errors }" name="Nationality" rules="required">
                    <b-form-select class="form-group" v-model="form.student_nationality">
                      <b-form-select-option v-for="(item, index) in countries_list" :key="index" :value="item.isoCode">
                        {{ item.name }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Mobile No" label-for="mobile-no">
                  <div class="form-label-group">
                    <validation-provider #default="{ errors }" name="Mobile No" rules="required">
                      <vue-tel-input :value="form.user_mobile" @input="(e) => (form.user_mobile = e)" class="py-25"
                        id="mobile-no" v-on:country-changed="
                          countryChanged($event, 'user_mobile')
                          " :dropdownOptions="{
                            showSearchBox: true,
                            showFlags: true,
                            showDialCodeInSelection: true,
                          }" :inputOptions="{
                          placeholder: 'Mobile No',
                          required: true,
                          showDialCode: false,
                        }" defaultCountry="IN"></vue-tel-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label-for="mc-email" label="Email">
                  <div class="form-label-group">
                    <validation-provider #default="{ errors }" name="Email Address" rules="required">
                      <b-form-input maxlength="255" id="mc-email" type="email" placeholder="Email"
                        v-model="form.user_email" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Marital Status">
                  <b-row>
                    <b-col>
                      <b-form-radio v-model="form.marital_status" plain value="unmarried">
                        Unmarried
                      </b-form-radio>
                    </b-col>
                    <b-col>
                      <b-form-radio v-model="form.marital_status" plain value="married">
                        Married
                      </b-form-radio>
                    </b-col>
                    <b-col>
                      <b-form-radio v-model="form.marital_status" plain value="divorced">
                        Divorced
                      </b-form-radio>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Passport No" label-for="passport-no">
                  <validation-provider #default="{ errors }" name="Passport No" rules="required">
                    <b-form-input maxlength="255" id="previous-name" placeholder="Passport No"
                      v-model="form.student_passport_no" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="form-card my-lg-3">
            <b-row>
              <b-col md="12">
                <h4>Address</h4>
                <br />
              </b-col>
              <b-col md="12">
                <b-form-group label=" Address" label-for="-address">
                  <validation-provider #default="{ errors }" name="Address" rules="required">
                    <b-form-input maxlength="255" id="-address" v-model="form.student_address" placeholder=" Address" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Country" label-for="mc-country">
                  <validation-provider #default="{ errors }" name="Country" rules="required">
                    <b-form-select class="form-group" v-model="form.student_country" @change="onSelectCountry">
                      <b-form-select-option v-for="(item, index) in countries_list" :key="index" :value="item.isoCode">
                        {{ item.name }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="State" label-for="mc-state">
                  <validation-provider #default="{ errors }" name="State" rules="required">
                    <b-form-select class="form-group" v-model="form.student_state" @change="onSelectState">
                      <b-form-select-option v-for="(item, index) in states_list" :key="index" :value="item.isoCode">
                        {{ item.name }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="City" label-for="mc-city">
                  <validation-provider #default="{ errors }" name="City" :rules="cities_list.length ? 'required' : ''">
                    <!-- :rules="cities_list.length ? 'required' : ''" -->
                    <!-- {{ cities_list.length ? "required" : "" }} -->

                    <b-form-select class="form-group" v-model="form.student_city">
                      <b-form-select-option v-for="(item, index) in cities_list" :key="index" :value="item.name">
                        {{ item.name }}
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Pin code" label-for="pin-code">
                  <div class="form-label-group">
                    <validation-provider #default="{ errors }" name="pincode" rules="required">
                      <b-form-input maxlength="255" type="number" id="pin-code" v-model="form.student_pincode"
                        placeholder="Pin code" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="form-card">
            <b-row>
              <b-col md="12">
                <h4 class="form-group">Parent/Guardian Contact</h4>
                <br />
              </b-col>
              <b-col md="4">
                <b-form-group label="Name" label-for="name">
                  <div class="form-label-group">
                    <validation-provider #default="{ errors }" name="Name" rules="required">
                      <b-form-input maxlength="255" id="name" placeholder="Name" v-model="form.student_ec_name" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Mobile" label-for="mobile-no">
                  <div class="form-label-group">
                    <validation-provider #default="{ errors }" name="Mobile Number" rules="required">
                      <vue-tel-input :value="form.student_ec_mobile" @input="(e) => (form.student_ec_mobile = e)"
                        class="py-25" id="mobile-no" v-on:country-changed="
                          countryChanged($event, 'student_ec_mobile')
                          " :dropdownOptions="{
                            showSearchBox: true,
                            showFlags: true,
                            showDialCodeInSelection: true,
                          }" :inputOptions="{
                          placeholder: 'Mobile No',
                          required: true,
                          showDialCode: false,
                        }" defaultCountry="IN"></vue-tel-input>
                      <!-- <b-form-input
                        maxlength="255"
                        type="number"
                        id="mobile-no"
                        v-model="form.student_ec_mobile"
                        placeholder="Mobile"
                      /> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Relation" label-for="landline-no">
                  <div class="form-label-group">
                    <b-form-input maxlength="255" id="relation-no" v-model="form.student_ec_relation"
                      placeholder="Relation" />
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label-for="mc-email" label="Email">
                  <div class="form-label-group">
                    <validation-provider #default="{ errors }" name="Email" rules="required">
                      <b-form-input maxlength="255" id="mc-email" type="email" placeholder="Email"
                        v-model="form.student_ec_email" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </validation-observer>
      </tab-content>

      <!-- Second tab -->
      <tab-content title="Questions" :before-change="validatePreference">
        <validation-observer ref="preference" tag="form" class="form-card">
          <b-row>
            <b-col md="12" class="mb-2">
              <h4>Questions</h4>
              <small class="text-muted"></small>
            </b-col>
            <!-- <b-col md="4">
              <b-form-group label="Preferred Country">
                <validation-provider
                  #default="{ errors }"
                  name="Prefered Country"
                  rules="required"
                >
                  <v-select
                    multiple
                    :reduce="(country) => country.country_id"
                    label="country_name"
                    v-model="form.pref_country_id"
                    :options="$store.getters['home/getCountries']"
                    :closeOnSelect="false"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col md="4">
              <b-form-group label="Preferred Course Level">
                <validation-provider #default="{ errors }" name="Prefered Course Level" rules="required">
                  <b-form-select class="form-group" v-model="form.pref_course_level_id" @change="onCourseLevelSelect">
                    <b-form-select-option v-for="(item, index) in $store.getters[
                      'home/getCourseLevels'
                    ]" :key="'p_level_' + index" :value="item.course_level_id">
                      {{ item.course_level_name }}
                    </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Preferred Discipline">
                <validation-provider #default="{ errors }" name="Prefered Discipline" rules="required">
                  <b-form-select class="form-group" v-model="form.pref_course_category_id">
                    <b-form-select-option v-for="(item, index) in $store.getters[
                      'home/getCourseCategories'
                    ]" :key="'p_category_' + index" :value="item.course_category_id">
                      {{ item.course_category_name }}
                    </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Preferred Intake Month">
                <validation-provider #default="{ errors }" name="Prefered Intake Month" rules="required">
                  <v-select multiple v-model="form.pref_intake_month" :options="all_months" :closeOnSelect="false" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Preferred Intake Year">
                <validation-provider #default="{ errors }" name="Prefered Intake Year" rules="required">
                  <!-- <b-form-select
                    class="form-group"
                    v-model="form.pref_intake_year"
                  >
                    <b-form-select-option
                      v-for="(year, index) in all_years"
                      :key="'p_intake_year_' + index"
                      :value="year"
                    >
                      {{ year }}
                    </b-form-select-option>
                  </b-form-select> -->
                  <v-select multiple v-model="form.pref_intake_year" :options="all_years" :closeOnSelect="false" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Tuition Budget - per annum">
                <!-- <validation-provider
                #default="{ errors }"
                name="Tuition Budget - per annum"
                rules="required"
              > -->
                <b-form-select class="form-group" v-model="form.tuition_budget">
                  <b-form-select-option v-for="(item, index) in budgets" :key="'budget_' + index" :value="item.value">
                    {{ item.label }}
                  </b-form-select-option>
                </b-form-select>
                <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Third Tab -->
      <tab-content title="Documents">
        <div class="form-card">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h4>DOCUMENTS</h4>
              <small class="text-muted"></small>
            </b-col>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">File Name</th>
                  <th scope="col" style="text-align: center">Uploaded File</th>
                  <th scope="col" style="text-align: center">
                    Upload Document
                  </th>
                  <!-- <th scope="col">Last Updated</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(doc, index) in docs_required" :key="'docs_required_' + index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>
                    {{ doc.doc_name }} {{ doc.is_mandatory === "Y" ? "*" : "" }}
                  </td>
                  <td style="text-align: center">
                    <div role="buttton" v-if="doc.doc_url" @click="(e) => openDoc(doc.doc_url)"
                      :disabled="doc.doc_url ? false : true">
                      <feather-icon icon="EyeIcon" size="20" class="text-success" />
                    </div>
                    <b-badge v-else style="margin-right: 10px" pill variant="light-primary">
                      not uploaded
                    </b-badge>
                    <!-- <b-button v-else :disabled="true">  </b-button> -->
                  </td>
                  <td style="text-align: center">
                    <feather-icon v-if="doc.doc_url" icon="CheckCircleIcon" size="20" color="green" />
                    <b-form-group v-else :label-for="'file-default_' + doc.doc_id">
                      <b-form-file :id="'file-default_' + doc.doc_id" v-model="doc.doc_file" @input="(e) => {
                        docUpload(index, doc, e);
                      }
                        " />
                    </b-form-group>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mt-2">
              <p>
                <b>Note:</b> Please upload a clear scanned copy of your
                originial documents. <br />
                Accepted formats are PDF, DOC, DOCX, PNG, JPEG and JPG only. Max
                file size ~5MB. Only 5 files can be uploaded at a time.
              </p>
            </div>
          </b-row>
        </div>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("lazy");

import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Datepicker from "vuejs-datepicker";
import {
  BRow,
  BCol,
  BCard,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BButton,
  BFormSelect,
  BFormRadioGroup,
  BFormSelectOption,
  BFormFile,
  BFormRadio,
  BFormTags,
  BFormTag,
  BFormCheckbox,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BAvatar,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import { required, email } from "@validations";
import {
  all_months,
  budgets,
  getAllYears,
  pg_docs,
  ug_docs,
} from "@/assets/json_data/data";
import StudentServices from "@/apiServices/StudentServices";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";
import { TokenService } from "@/apiServices/storageService";
import { FILESURL, BASEURL } from "@/config";
import { Country, State, City } from "country-state-city";
import { consent_html_text } from "./consentFormHtml";
import store from "@/store";

export default {
  components: {
    BFormRadio,
    BFormCheckboxGroup,
    BFormRadioGroup,
    ValidationProvider,
    BButton,
    BFormDatepicker,
    BFormSelect,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormSelectOption,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    BFormTags,
    BFormTag,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BAvatar,
    BCardText,
    Datepicker,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      errors: {},
      form: {
        user_profile_image: null,

        // Personal Information
        user_firstname: null,
        user_lastname: null,
        user_mobile: null,
        // user_country_code: "IN",
        // user_mobile_code: "+91",
        user_email: null,
        marital_status: null,
        student_gender: null,
        student_DOB: null,
        student_nationality: null,
        student_passport_no: null,
        student_passport_applied: null,

        // Address
        student_address: null,
        student_country: null,
        student_state: null,
        student_city: null,
        student_pincode: null,

        // Emergency Contact
        student_ec_name: null,
        student_ec_mobile: null,
        student_ec_relation: null,
        student_ec_email: null,

        // Student Preferences
        pref_country_id: [],
        pref_course_level_id: null,
        pref_course_category_id: null,
        pref_intake_month: [],
        pref_intake_year: [],
        tuition_budget: null,

        student_consent: "N",
      },

      uploaded_image: null,

      consent_html_text,

      // Docs Required
      docs_required: [],

      countries_list: [],
      states_list: [],
      cities_list: [],
      all_months,
      // all_years: [],
      ug_docs,
      pg_docs,
      budgets,
      FILESURL,
      required,
      email,
    };
  },
  computed: {
    all_years() {
      return getAllYears();
    },
  },
  methods: {
    countryChanged(input, property) {
      if (this.form[`${property}`].split(" ").length > 1) {
        this.form[`${property}`] =
          "+" + input.dialCode + " " + this.form[`${property}`];
      } else {
        this.form[`${property}`] = "+" + input.dialCode;
      }
    },

    invalidContactInformation() {
      const email = this.form.user_email;
      const mobile = this.form.user_mobile;
      const emergencyEmail = this.form.student_ec_email;
      const emergencyMobile = this.form.student_ec_mobile;

      let emailsValid = false;
      let mobilesValid = false;

      if (!mobile || !emergencyMobile) {
        mobilesValid = true;
      } else {
        mobilesValid = mobile == emergencyMobile;
      }

      if (!email || !emergencyEmail) {
        emailsValid = true;
      } else {
        emailsValid = email == emergencyEmail;
      }

      if (emailsValid || mobilesValid) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Emergency Contact Information Cannot Be Same.",
            icon: "EditIcon",
            variant: "error",
          },
        });
        return true;
      }
      return false;
    },

    validatePersonalDetails() {
      if (this.invalidContactInformation()) return false;
      return new Promise((resolve, reject) => {
        // .replace so that the spaces do not matter when validating
        const studentMobile = this.form.user_mobile.replace(/\s/g, "");
        const studentECMobile = this.form.student_ec_mobile;

        if (studentMobile === studentECMobile) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Parent/Guardian number cannot be the same as student mobile number",
              icon: "EditIcon",
              variant: "error",
            },
          });
          reject();
        } else {
          this.$refs.personalDetails.validate().then((success) => {
            if (success) {
              this.saveStudentProfile();
              resolve(true);
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fill all necessary fields",
                  icon: "EditIcon",
                  variant: "error",
                },
              });
              reject();
            }
          });
        }
      });
    },

    validatePreference() {
      return new Promise((resolve, reject) => {
        this.$refs.preference.validate().then((success) => {
          if (success) {
            this.showGoToModal();
            this.saveStudentProfile();
            resolve(true);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fill all neccessary feilds",
                icon: "EditIcon",
                variant: "error",
              },
            });
            reject();
          }
        });
      });
    },

    showGoToModal() {
      this.$refs["ref-go-to-modal"].show();
    },

    goToUniversitySearch() {
      localStorage.removeItem("search_filters");
      this.$router.push("/universitysearch");
    },

    onComplete() {
      let adhar_or_passport_flag = false;

      for (let i = 0; i < this.docs_required.length; i++) {
        const doc = this.docs_required[i];

        if ([1, 24].includes(doc.doc_id)) {
          if (doc.doc_url) {
            adhar_or_passport_flag = true;
          }
          continue;
        }

        if (doc.is_mandatory === "Y" && !doc.doc_url) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Upload All Required Docs",
              icon: "EditIcon",
              variant: "failure",
            },
          });
          return;
        }
      }
      if (!adhar_or_passport_flag) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "If passport is not available please upload Adhar card",
            icon: "EditIcon",
            variant: "primary",
          },
        });
        return;
      }
      if (this.form.student_consent !== "Y") {
        this.$refs["ref-consent-modal"].show();
      } else {
        this.saveStudentProfile();
      }
    },

    acceptConsent(e) {
      if (this.form.student_consent === "Y") {
        this.saveStudentProfile();
        this.$nextTick(() => {
          this.$refs["ref-consent-modal"].hide();
        });
      } else {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "You Have To Accept The Consent",
            icon: "X",
            variant: "failure",
          },
        });
      }
    },

    async uploadProfileImage() {
      try {
        const AUTH_TOKEN = TokenService.getToken();
        const formData = new FormData();
        formData.append("image", this.uploaded_image);
        const response = await axios.post(
          `${BASEURL}/common/upload-profile-image`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              authorization: AUTH_TOKEN,
            },
          }
        );

        if (response.data.status) {
          this.form.user_profile_image = response.data.data;
          let user_profile = store.getters["user/getUserDetails"];
          user_profile.user_profile_image = response.data.data;
          this.$store.commit("user/SET_USER_DETAILS", user_profile);
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Profile Image not Uploaded",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.error("Error uploading profile image ", err);
      }
    },
    saveStudentProfile() {
      if (this.invalidContactInformation()) return;

      let studentDetails = {
        ...this.form,
        pref_country_id: this.form.pref_country_id.toString(),
        pref_intake_month: this.form.pref_intake_month.toString(),
        pref_intake_year: this.form.pref_intake_year.toString(),
      };

      StudentServices.saveStudentProfile(studentDetails)
        .then((response) => {
          if (response.data.status) {
            const user_data = store.getters["user/getUserDetails"];

            this.$store.commit("user/SET_USER_DETAILS", {
              ...user_data,
              ...response.data.data,
            });

            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Profile Saved Successfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.getStudentProfile();
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Profile Save Failed",
                icon: "EditIcon",
                variant: "primary",
              },
            });
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Server Error",
              icon: "EditIcon",
              variant: "primary",
            },
          });
        });
    },
    async docUpload(index, doc, e) {
      try {
        const format = /[^\x00-\x7F]/;
        if (format.test(e.name)) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Filename cannot contain special characters",
              icon: "XIcon",
              variant: "failure",
            },
          });
          return;
        }
        if (e.size > 10 * 1024 * 1024) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Filesize cannot be greater than 5MB",
              icon: "XIcon",
              variant: "failure",
            },
          });
          return;
        }

        const AUTH_TOKEN = TokenService.getToken();

        const formData = new FormData();
        formData.append("doc", e);

        const response = await axios.post(
          `${BASEURL}/student/document/upload/${doc.doc_id}`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              authorization: AUTH_TOKEN,
            },
          }
        );

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Document Uploaded Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.docs_required[index].doc_url = response.data.data.file_path;
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Document Uploaded Failed",
              icon: "XIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.error("Error uploading file ", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "XIcon",
            variant: "failure",
          },
        });
      }
    },
    openDoc(doc_url) {
      const url = FILESURL + doc_url;
      window.open(url);
    },
    onCourseLevelSelect(e) {
      StudentServices.getRequiredDocs({
        course_level_id: this.form.pref_course_level_id,
      }).then((response) => {
        if (response.data.status) {
          this.docs_required = [];
          this.docs_required = response.data.data.map((doc) => {
            return {
              ...doc,
              doc_file: null,
            };
          });
        }
      });
    },
    onSelectCountry(country_code) {
      this.states_list = [];
      this.cities_list = [];

      this.states_list = State.getStatesOfCountry(country_code);
      this.cities_list = City.getCitiesOfCountry(country_code);
    },
    onSelectState(state_code) {
      this.cities_list = [];

      this.cities_list = City.getCitiesOfState(
        this.form.student_country,
        state_code
      );
    },
    getNumberArray(list) {
      if (!list) return [];
      const stringList = list.split(",");
      let finalList = [];
      for (let item of stringList) {
        finalList.push(parseInt(item));
      }

      return finalList;
    },
    getStringArray(list) {
      if (!list) return [];
      const stringList = list.split(",");
      return stringList;
    },
    getStudentProfile() {
      StudentServices.getStudentProfile()
        .then((response) => {
          let profile_data = response.data.data;
          this.form = {
            ...profile_data,
            pref_country_id: this.getNumberArray(profile_data.pref_country_id),
            pref_intake_month: this.getStringArray(
              profile_data.pref_intake_month
            ),
            pref_intake_year: this.getStringArray(
              profile_data.pref_intake_year
            ),
          };

          if (profile_data.student_country) {
            this.onSelectCountry(profile_data.student_country);
          }
          if (profile_data.student_state) {
            this.onSelectState(profile_data.student_state);
          }
          if (profile_data.pref_course_level_id) {
            this.form.pref_course_level_id = profile_data.pref_course_level_id;
            this.onCourseLevelSelect();
          }
        })
        .catch((error) => {
          console.error("Error getStudentProfile ", error);
        });
    },
    getAllCountries() {
      this.countries_list = Country.getAllCountries();
      // this.states_list = State.getAllStates();
      // this.cities_list = City.getAllCities();
    },
  },
  mounted() {
    this.getStudentProfile();
    this.getAllCountries();
    // this.setAllYears();
    this.$store.dispatch("home/onGetCountries");
    this.$store.dispatch("home/onGetCourseLevels");
    this.$store.dispatch("home/onGetCourseCategories");
  },
};
</script>
<style>
.consent-html-text {
  height: 40vh;
  overflow-y: scroll;
}

.profile-form-wizard .wizard-nav {
  justify-content: center !important;
}

.profile-form-wizard .form-card {
  border: 2px solid #d8d6de;
  border-radius: 05px;
  padding: 20px;
}

.profile-form-wizard .form-card h4 {
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
}

.profile-form-wizard .form-card .form-group label {
  font-size: 13px !important;
  color: #000 !important;
}

.profile-form-wizard .form-card .form-group legend {
  font-size: 13px !important;
  color: #000 !important;
}

.custom-select {
  margin-bottom: 0px;
}
[dir] .vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container{
  background-color: var(--primary) !important;
}
</style>
